import React, { FC } from "react"

import Layout from "../components/layout"
import { PageProps } from "gatsby"

const ImpressumPage: FC<PageProps> = () => (
  <Layout>
    [German law demands this]
    <h1>Impressum</h1>
    <h2>Angaben gemäß § 5 TMG:</h2>
    <h2>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:</h2>
    <p>
      Manuel Mauky
      <br />
      Demianiplatz 34
      <br />
      02826 Görlitz
    </p>
    <h2>Kontakt:</h2>
    <p>
      Telefon: +49 (0) 1525 6322383
      <br />
      E-Mail: manuel.mauky@gmail.com
    </p>
    <a href="/gpgkey.asc">PGP-Schlüssel</a> (
    <a href="https://pgp.key-server.io/0x07E2565BFCE02305">vom PGP Keyserver</a>
    ).
    <h1>Datenschutz</h1>
    Der Webserver dieser Webseite speichert IP-Adressen der Besucher in Logdateien. Diese dienen der Fehlersuche im
    Krisenfall und werden ansonsten nicht ausgewertet. Diese Webseite benutzt keine Tracking-Tools oder Cookies. Es
    werden keine weiteren Daten erhoben, gespeichert oder ausgewertet.
  </Layout>
)

export default ImpressumPage
